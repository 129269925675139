import { IconBuilder } from "/app/src/components/icons/IconBuilder";

const EditButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <button
      className="bg-none cursor-pointer p-2 border-0 hover:opacity-100"
      onClick={onClick}
      disabled={disabled}
    >
      <IconBuilder icon="Edit" />
    </button>
  );
};

export default EditButton;
